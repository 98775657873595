<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>Cambiar Estado</h4>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <div class="demo-alignment">
          <p>Seleccionar el estado de la solicitud.</p>
          <v-select v-model="selected" browserAutocomplete="off" autocomplete="off" class="mt-5 w-full" name="estado" v-validate="'required'" placeholder="Seleccione estado..." label="statusName" :options="statusSelector" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm" v-show="errors.has('estado')">{{ errors.first('estado') }}</span>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
       <vs-button class="mr-6" @click="submitData" id="saveButton">Guardar</vs-button>
      <!-- <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Guardar</vs-button> -->
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let id = JSON.parse(JSON.stringify(this.data))
        this.dataId = id.id
        this.initValues()
      }
    }
  },
  data() {
    return {
       statusSelector: [
        {
          status: 3,
          statusName: "Entregado",
        },
        {
          status: 4,
          statusName: "Rechazado",
        }],

      selected:null,
      dataId: null,

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
   /*  isFormValid() {
      return !this.errors.any() && this.statusName == ''
    }, */
  },
  methods: {
    initValues() {
      if(this.data.id) return
        this.dataId = null
    },
    submitData() {
      this.$validator.validateAll().then(status => {
        
        if (!status)
          return;

        this.disableSaveButton();

        let action = this.selected.status == 3 ? "GiveRewardsPetitions" : (this.selected.status == 4 ? "RejectedRewardsPetitions" : "") 
        
        if(action && action != "") {
          this.$store.dispatch(`reportModule/${action}`, this.dataId)
          .then(() => {
            this.submitSuccess()
          })
          .catch(err => { this.submitError(err) })
        } else {
          this.submitError("Estado de solicitud inválido.")
        }

      })
    },
    submitSuccess() {
      this.showAddSuccess()
      this.enableSaveButton()
      this.initValues()
      this.$emit('closeSidebar')
      this.$emit("reloadTable")
    },
    submitError(msg) {
      this.enableSaveButton()
      this.showAddError(msg)
    },
    showAddSuccess() {
      this.$vs.notify({
        color: 'success',
        title: 'Premio',
        text: 'Se han guardado los cambios.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-check',
      })
    },
    showAddError(msg) {
      this.$vs.notify({
        color: 'danger',
        title: 'Premio',
        text: msg || 'Error al guardar los cambios.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-alert-circle',
      })
    },
    disableSaveButton() {
      this.loading = true
      this.$vs.loading({
        background: this.backgroundLoading,
        color: 'primary',
        container: "#saveButton",
        scale: 0.45
      })
    },
    enableSaveButton() {
      this.loading = false
      this.$vs.loading.close("#saveButton > .con-vs-loading")
    },
  },
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
  },
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>