<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <span><vs-button v-show="this.params.data.status === 1" @click="editRecord()">Cambiar estado</vs-button></span>
      <!-- <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" /> -->
      <!-- <feather-icon icon="RefreshCwIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" v-if="this.params.status == 4"/> -->
    </div>
</template>

<script>
    export default {
        name: 'CellRendererActionsRequestsRewards',
        methods: {
          editRecord() {
            this.params.context.componentParent.sidebarData = this.params.data;
            this.params.context.componentParent.requestsSidebarActive = true;
          },
        },
    }
</script>
